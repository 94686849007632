<template>
  <v-card class="auth-card">
    <!-- logo -->
    <v-card-title class="d-flex align-center justify-center py-7">
      <router-link
        to="/home"
        class="d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-width="150px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>
      </router-link>
      <CardI18n />
    </v-card-title>
    <v-card-text>
      <div class="primary--text text-center mb-4">
        {{ $t('LeGrandJardin') }}
      </div>
      <template v-if="!phoneChecked">
        <p class="text-center text-2xl font-weight-semibold text--primary mb-2">
          {{ $t('RegisterTitle') }}
        </p>
        <p class="mb-2">
          {{ $t('RegisterSubtitle') }}
        </p>
      </template>
      <template v-else>
        <p class="text-center text-2xl font-weight-semibold text--primary mb-2">
          {{ $t('ConfirmOTP') }}
        </p>
        <p class="mb-2">
          {{ $t('ConfirmOTPSubtitle', { phoneNumber: registerInformation.phone }) }}
        </p>
      </template>
    </v-card-text>

    <!-- login form -->
    <v-card-text>
      <v-form
        v-if="!phoneChecked"
        @submit.prevent="checkPhoneExisted"
      >
        <v-text-field
          ref="phoneNumberField"
          v-model="registerInformation.phone"
          dense
          outlined
          type="tel"
          :label="$t('PhoneNumber')"
          :placeholder="$t('PhoneNumber')"
          :rules="[validators.required, validators.phoneNumberValidator]"
          hide-details="auto"
          class="mb-3"
          validate-on-blur
          :error-messages="errorMessage"
        >
        </v-text-field>
        <v-btn
          block
          color="primary"
          type="submit"
          class="mt-6"
          :loading="loading"
        >
          {{ $t('Next') }}
        </v-btn>
      </v-form>
      <v-form
        v-else
        ref="confirmOTPForm"
        @submit.prevent="handleFormSubmit"
      >
        <v-text-field
          v-model="otp"
          dense
          outlined
          :label="$t('EnterOTP')"
          :placeholder="$t('EnterOTP')"
          :rules="[validators.required]"
          hide-details="auto"
          class="mb-3"
          validate-on-blur
          :suffix="time > 0 ? $t('ResendAfterTime', {time: time}): ''"
          :append-icon="time > 0 ? '' : icons.mdiReload"
          @click:append="handleGetOtp"
        >
        </v-text-field>

        <v-btn
          block
          color="primary"
          type="submit"
          class="mt-6"
          :loading="loading"
        >
          {{ $t('Submit') }}
        </v-btn>
      </v-form>
    </v-card-text>
    <v-card-actions

      class="d-flex justify-center align-center"
    >
      <a
        v-if="phoneChecked"
        @click="handleGoBack"
      >
        <v-icon
          size="24"
          color="primary"
        >
          {{ icons.mdiChevronLeft }}
        </v-icon>
        <span>{{ $t('BackToRegister') }}</span>
      </a>
      <router-link
        v-else
        :to="{name:'auth-login'}"
      >
        <v-icon
          size="24"
          color="primary"
        >
          {{ icons.mdiChevronLeft }}
        </v-icon>
        <span>{{ $t('BackToLogin') }}</span>
      </router-link>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiChevronLeft, mdiReload } from '@mdi/js'
import { required, phoneNumberValidator } from '@core/utils/validation'
import { ref, inject, watch } from '@vue/composition-api'
import CardI18n from '@/components/CardI18n.vue'
import store from '@/store'
import axios from '@/plugins/axios'
import { useIntervalFn } from '@vueuse/core'

const TIMER = 120

export default {
  components: { CardI18n },
  emits: ['onBack', 'onSubmit'],
  setup(_, { emit }) {
    const phoneNumberField = ref(null)
    const confirmOTPForm = ref(null)
    const registerInformation = inject('userRegisterInformation', {})

    const checkingPhone = ref(false)
    const phoneChecked = ref(false)

    const otp = ref('')
    const loading = ref(false)

    const errorMessage = ref('')

    const time = ref(0)

    const { resume: startTimer, pause: pauseTimer } = useIntervalFn(
      () => {
        time.value -= 1
      },
      1000,
      false,
    )

    const resetTimer = () => {
      time.value = TIMER
      startTimer()
    }

    watch(time, value => {
      if (value === 0) {
        pauseTimer()
      }
    })

    const handleGetOtp = async (isResetTimer = true) => {
      const resp = await axios.post('/v1/account/getOtp', {
        phone: registerInformation.value.phone,
        action_otp: 'register',
      })

      if (isResetTimer) {
        resetTimer()
      }

      // ! Hardcode OTP
      otp.value = resp.data.data.otp_debug
    }

    const checkPhoneExisted = async () => {
      const isPhoneNumberValid = phoneNumberField.value.validate()

      if (isPhoneNumberValid) {
        checkingPhone.value = true
        try {
          const resp = await axios.post('/v1/account/phone/check', {
            phone: registerInformation.value.phone,
          })
          const isPhoneNumberExisted = resp.data.data.result

          if (isPhoneNumberExisted) {
            store.commit('snackbar/show', {
              message: 'This phone number has already existed',
              type: 'error',
            })
            errorMessage.value = 'This phone number has already existed'
          } else {
            store.commit('snackbar/show', {
              message: 'This phone number is available',
              type: 'success',
            })
            handleGetOtp(true)
            phoneChecked.value = true
          }
        } catch {
        } finally {
          checkingPhone.value = false
        }
      }
    }

    const handleFormSubmit = async () => {
      const isFormValid = confirmOTPForm.value.validate()

      if (!isFormValid) return
      loading.value = true
      try {
        await axios.post('/v1/account/checkOtp', {
          phone: registerInformation.value.phone,
          otp_code: otp.value,
        })
        registerInformation.value.otp_code = otp.value
        emit('onSubmit')
      } catch (error) {
        store.commit('snackbar/show', {
          message: error.message,
          type: 'error',
        })
      } finally {
        loading.value = false
      }
    }

    const handleGoBack = () => {
      emit('onBack')
      phoneChecked.value = false
    }

    return {
      otp,
      time,
      loading,
      handleFormSubmit,
      handleGoBack,
      handleGetOtp,
      registerInformation,
      phoneNumberField,
      checkPhoneExisted,
      checkingPhone,
      phoneChecked,
      errorMessage,
      icons: {
        mdiReload,
        mdiChevronLeft,
      },
      validators: {
        required,
        phoneNumberValidator,
      },

      // Template Refs
      confirmOTPForm,
    }
  },
}
</script>
