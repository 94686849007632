<template>
  <v-card class="auth-card">
    <!-- logo -->
    <v-card-title class="d-flex align-center justify-center py-7">
      <router-link
        to="/home"
        class="d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-width="150px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>
      </router-link>
      <CardI18n />
    </v-card-title>

    <!-- title -->
    <v-card-text>
      <div class="primary--text text-center mb-4">
        {{ $t('LeGrandJardin') }}
      </div>
      <p class="text-center text-2xl font-weight-semibold text--primary mb-2">
        {{ $t('RegisterTitle') }}
      </p>
    </v-card-text>

    <!-- login form -->
    <v-card-text>
      <v-form
        ref="registerForm"
        @submit.prevent="handleFormSubmit"
      >
        <v-text-field
          v-model="registerInformation.full_name"
          dense
          outlined
          :label="$t('Fullname')"
          :rules="[validators.required]"
          :placeholder="$t('Fullname')"
          hide-details="auto"
          class="mb-3"
          validate-on-blur
        ></v-text-field>

        <v-select
          v-model="registerInformation.gender"
          dense
          outlined
          :label="$t('Gender')"
          :placeholder="$t('Gender')"
          :items="[
            {text: $t('Male'), value: 1},
            {text: $t('Female'), value: 2},
            {text: $t('Other'), value: 0}
          ]"
          :rules="[validators.required]"
          hide-details="auto"
          class="mb-3"
          validate-on-blur
        ></v-select>

        <v-autocomplete
          v-model="registerInformation.year_of_birth"
          dense
          outlined
          :items="yearsOfBirth"
          :label="$t('YearOfBirth')"
          :placeholder="$t('YearOfBirth')"
          :rules="[validators.required]"
          hide-details="auto"
          class="mb-3"
          validate-on-blur
        >
        </v-autocomplete>

        <v-text-field
          v-model="registerInformation.building"
          dense
          outlined
          :label="$t('Building')"
          :rules="[validators.required]"
          :placeholder="$t('Building')"
          hide-details="auto"
          class="mb-3"
          validate-on-blur
        ></v-text-field>

        <v-text-field
          v-model="registerInformation.room_number"
          dense
          outlined
          :label="$t('RoomNumber')"
          :rules="[validators.required]"
          :placeholder="$t('RoomNumber')"
          hide-details="auto"
          class="mb-3"
          validate-on-blur
        ></v-text-field>

        <v-card-title class="page-title text-no-wrap pl-0">
          Photo clearly!
        </v-card-title>

        <img
          :src="require('@/assets/images/pages/register_face.png')"
          width="100%"
        />

        <v-card-subtitle class="mt-4 px-0">
          Your photo should clearly show the face.
        </v-card-subtitle>

        <v-row>
          <v-col
            cols="6"
            class="text-center"
          >
            <Upload
              v-model="faceImageUpload"
              :auto-sizing="true"
            />
            <div>
              {{ $t('FaceImage') }}
            </div>
          </v-col>
          <v-col
            cols="6"
            class="text-center"
          >
            <Upload
              v-model="faceMaskImageUpload"
              :auto-sizing="true"
            />
            <div>
              {{ $t('FaceWithMaskImage') }}
            </div>
          </v-col>
        </v-row>

        <v-checkbox
          dense
          outlined
          :rules="[validators.required]"
          hide-details="auto"
          class="mb-3"
          validate-on-blur
        >
          <template v-slot:label>
            <div>
              {{ $t('AgreeToTerms') }}
              <a
                class="secondary--text text-decoration-underline"
                target="_blank"
                @click.stop="showTOS = true"
              >
                {{ $t('TermsOfService') }}
              </a>
            </div>
          </template>
        </v-checkbox>

        <v-btn
          block
          color="primary"
          type="submit"
          class="mt-6"
          :loading="loading"
        >
          {{ $t('Submit') }}
        </v-btn>
      </v-form>
    </v-card-text>

    <!-- create new account  -->
    <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
      <span class="me-2">
        {{ $t('LoginSuggestion') }}
      </span>
      <router-link :to="{name:'auth-login'}">
        {{ $t('Login') }}
      </router-link>
    </v-card-text>
    <v-dialog
      v-model="showTOS"
      scrollable
      fullscreen
    >
      <v-card>
        <v-card-title class="text-uppercase">
          {{ $t('TermsOfService') }}
          <v-spacer />
          <v-btn
            icon
            small
            @click="showTOS = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <component :is="tac"></component>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            color="error"
            @click="showTOS = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { required, phoneNumberValidator, alphaValidator } from '@core/utils/validation'
import { ref, inject } from '@vue/composition-api'
import CardI18n from '@/components/CardI18n.vue'
import Upload from '@/components/Upload/Upload.vue'
import UploadService from '@/services/UploadService'
import AccountService from '@/services/AccountService'
import store from '@/store'
import { mdiClose } from '@mdi/js'

import Tac from '@/views/Tac.vue'
import TacEn from '@/views/Tac-en.vue'

export default {
  components: { CardI18n, Upload },
  emits: 'onSubmitInformation',
  computed: {
    tac() {
      return this.$i18n.locale === 'en' ? TacEn : Tac
    },
  },
  setup(_, { emit }) {
    const registerForm = ref(null)
    const registerInformation = inject('userRegisterInformation', {})

    const faceImageUpload = ref({})
    const faceMaskImageUpload = ref({})

    const loading = ref(false)

    const showTOS = ref(false)

    const handleFormSubmit = async () => {
      const isFormValid = registerForm.value.validate()

      if (isFormValid) {
        loading.value = true
        try {
          await faceImageUpload.value
            .asyncBase64('image/jpeg', 0.8)
            .then(async base64 => {
              await UploadService.uploadFile({
                note: 'FaceImage',
                files: [base64],
                is_face: true,
              }).then(res => {
                const faceImageId = res.data?.data?.files?.[0]?.id_file
                registerInformation.value.face_image = faceImageId
              })
            })
            .catch(error => {
              console.error(error)
              store.commit('snackbar/show', {
                message: 'PleaseUpload',
                type: 'error',
              })
            })

          await faceMaskImageUpload.value
            .asyncBase64('image/jpeg', 0.8)
            .then(async base64 => {
              await UploadService.uploadFile({
                note: 'FaceMaskImage',
                files: [base64],
                is_face: true,
              }).then(res => {
                const faceMaskImageId = res.data?.data?.files?.[0]?.id_file
                registerInformation.value.face_mask_image = faceMaskImageId
              })
            })
            .catch(error => {
              console.error(error)
              store.commit('snackbar/show', {
                message: 'PleaseUpload',
                type: 'error',
              })
            })

          if (registerInformation.value.face_mask_image && registerInformation.value.face_mask_image) {
            await AccountService.registerUser({ ...registerInformation.value })
            emit('onSubmitInformation')
          }
        } catch (e) {
        } finally {
          loading.value = false
        }
      }
    }

    return {
      registerInformation,
      faceImageUpload,
      faceMaskImageUpload,
      loading,
      handleFormSubmit,
      showTOS,
      validators: {
        required,
        phoneNumberValidator,
        alphaValidator,
      },
      icons: {
        mdiClose,
      },

      // Template Refs
      registerForm,
    }
  },
}
</script>
